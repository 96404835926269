import icon2 from '../assets/images/icons/ab-2.webp';
import pic2 from '../assets/images/pic-ab-a-1.webp';
import cover from '../assets/images/cover.webp';
import icon from '../assets/svg/af-icon.webp';
import featuresPic from '../assets/images/as-feat.webp';

const forAgencies = {
  meta: {
    title: 'CraftFlow | For Agencies',
    description:
      'Discover talented designers and collaborate on projects. Join CraftFlow to find fresh talent and innovative ideas',
    canonical: 'https://example.com/for-agencies',
  },
  header: {
    joinNowText: 'Join Now',
    siteTitle: 'CraftFlow',
    lang: 'EN',
    login: 'Login',
    navLinks: [
      { href: '/for-designers', text: 'For Designers' },
      { href: '/for-agencies', text: 'For Agencies' },
      { href: '/for-business', text: 'For Business' },
      { href: '/design-contest', text: 'Design Contest' },
    ],
  },
  intro: {
    bg: 'foragencies',
    title: 'For Agencies',
    subtitle: 'Connect with Emerging Talent on CraftFlow',
    buttonText: 'Join as an Agency',
  },
  about: {
    title: 'Why Choose CraftFlow?',
    subtitle:
      'CraftFlow helps agencies discover new talent and foster creative collaborations',
    sections: [
      {
        id: 1,
        icon: icon2,
        image: pic2,
        title: 'Find Designers',
        description: 'Access a pool of talented designers ready to innovate',
        buttonText: 'Explore Designers',
        sub: 'Discover fresh perspectives for your projects',
        textColor: 'dark',
        titleColor: 'pink',
        link: '#offer',
        buttonColor: 'theme',
      },
    ],
  },
  features: {
    title: 'Features for Agencies',
    subtitle:
      'Tools and resources to help you collaborate and manage projects efficiently',
    feature: {
      icon: icon,
      title: 'Project Management',
      description: 'Manage your projects and teams effectively',
      sub: 'Streamline your workflow with our integrated tools',
      image: featuresPic,
    },
  },
  ctaOne: {
    bg: 'cta-a',
    text: 'Ready to find the next big talent?',
    buttonText: 'Join CraftFlow Now',
  },
  ctaTwo: {
    bg: 'cta-a',
    text: 'Discover How CraftFlow Can Benefit Your Agency',
    buttonText: 'Learn more',
  },
  tour: {
    video: 'Video',
    buttonText: 'Contact Us',
    title: 'Virtual Tour',
    subtitle:
      'Get a sneak peek of CraftFlow in action – Experience the future of design!',
    cover: cover,
  },
  join: {
    link: '#offer',
    title: 'Join the CraftFlow Community',
    subtitle:
      'Engage with our interactive challenges and quizzes designed for creative minds',
    showcases: [
      {
        title: 'Designers Showcase',
        items: 'designers',
        showAllLink: '/',
      },
      {
        title: 'Latest Agencies Creations',
        items: 'agencies',
        showAllLink: '/',
      },
      {
        title: 'AI Smartly Crafted',
        items: 'ai',
        showAllLink: '/',
      },
    ],
    action: {
      color: 'dark',
      back: 'pink',
      heading:
        'Join the ranks of thousands of designers who are eagerly waiting for CraftFlow',
      text: 'Join now to CraftFlow',
    },
  },
  faq: {
    title: 'Frequently Asked Questions',
    subtitle: 'Find answers to common queries from agencies',
    questions: [
      {
        question: 'How can I find designers suitable for my projects?',
        answer:
          'Use our advanced search and filtering tools to find designers that match your project requirements',
      },
      {
        question: 'Can I manage multiple projects simultaneously?',
        answer:
          'Yes, CraftFlow allows you to manage multiple projects and teams seamlessly',
      },
    ],
  },
  offer: {
    link: 'https://form.typeform.com/to/P9Re7Stt?kind=agency&page=For Agencies&button=Get Started',
    title: 'Exclusive Offers for Agencies',
    subtitle: 'Unlock premium features and priority support',
    description: 'Access premium services and dedicated support',
    buttonText: 'Get Started',
  },
  footer: {
    organization: 'Public Organization "DVOSTOK"',
    registrationNumber: 'Registration Number: 44740443',
    links: [
      { href: '/privacy-policy', text: 'Privacy Policy' },
      { href: '/terms-of-use', text: 'Terms of Use' },
      { href: '/cookie-policy', text: 'Cookie Policy' },
    ],
    copyright: `Copyright © ${new Date().getFullYear()} CraftFlow. All rights reserved.`,
  },
};

export { forAgencies };

import React from 'react';
import { Container } from 'react-bootstrap';
import { Title } from '../elements/Title';
import { Card } from '../cards/Card';

const Features = ({ content }) => {
  return (
    <Container className='tc'>
      <Title
        width={64}
        color='dark'
        sub='grey'
        title={content.title}
        subtitle={content.subtitle}
      />
      <div className='row g-0'>
        <div className='col-lg-6'>
          <Card
            back='features'
            sub={content.feature.sub}
            icon={content.feature.icon}
            width={60}
            height={85}
            title={content.feature.title}
            desc={content.feature.description}
            textColor='white'
            titleColor='yellow'
          />
        </div>
        <div className='col-lg-6'>
          <img
            src={content.feature.image}
            alt={content.feature.description}
            width={'100%'}
            height={480}
            className={'obfc'}
          />
        </div>
      </div>
      <div className='s-120'></div>
    </Container>
  );
};

export { Features };

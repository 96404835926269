import React from 'react';
import { Container } from 'react-bootstrap';
import { Title } from '../elements/Title';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit';

const FAQ = ({ content }) => {
  return (
    <section id='faq'>
      <Container>
        <Title
          color='dark'
          sub='grey'
          title={content.title}
          subtitle={content.subtitle}
        />
        <div className='w-80 m-100 ma'>
          <MDBContainer className='mt-5'>
            <MDBAccordion alwaysOpen initialActive={1}>
              {content.questions.map((item, index) => (
                <MDBAccordionItem
                  className='c-grey'
                  key={index}
                  collapseId={index + 1}
                  headerTitle={item.question}
                >
                  {item.answer}
                </MDBAccordionItem>
              ))}
            </MDBAccordion>
          </MDBContainer>
        </div>
        <div className='s-80'></div>
      </Container>
    </section>
  );
};

export { FAQ };

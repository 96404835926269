import React from 'react';
import { Container } from 'react-bootstrap';

const Action = (props) => {
  return (
    <Container className='tc'>
      <div className='s-64'></div>
      <h5 className={`title-section-sub c-${props.color}`}>{props.heading}</h5>
      <div className='s-24'></div>
      <a
        href={props.link}
        className={`button c-white small bg-${props.back}`}
        target={
          props.link === '/for-designers' ||
          props.text === 'Contact Us' ||
          props.link === '#offer'
            ? '_self'
            : 'blank'
        }
      >
        {props.text}
      </a>
      <div className='s-64'></div>
    </Container>
  );
};

export { Action };

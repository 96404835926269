import icon1 from '../assets/images/icons/ab-1.webp';
import pic1 from '../assets/images/pic-ab-d-1.webp';
import cover from '../assets/images/cover.webp';
import ideaIcon from '../assets/svg/df-icon.svg';
import featuresPic from '../assets/images/ds-feat.webp';

const forDesigners = {
  meta: {
    title: 'CraftFlow | For Designers',
    description:
      'Discover opportunities tailored for designers. Join CraftFlow to showcase your talent and connect with agencies and brands',
    canonical: 'https://example.com/for-designers',
  },
  header: {
    joinNowText: 'Join Now',
    siteTitle: 'CraftFlow',
    lang: 'EN',
    login: 'Login',
    navLinks: [
      { href: '/for-designers', text: 'For Designers' },
      { href: '/for-agencies', text: 'For Agencies' },
      { href: '/for-business', text: 'For Business' },
      { href: '/design-contest', text: 'Design Contest' },
    ],
  },
  intro: {
    bg: 'fordesigners',
    title: 'Unleash Your Creativity with CraftFlow',
    subtitle: 'Join our community of innovative designers today',
    buttonText: 'Join as a Designer',
  },
  about: {
    title: 'Why Join CraftFlow?',
    subtitle:
      'CraftFlow is the perfect platform for designers to grow, collaborate, and find new opportunities',
    sections: [
      {
        id: 2,
        icon: icon1,
        image: pic1,
        title: 'Showcase Your Work',
        description: 'Create a portfolio and get noticed by top agencies',
        buttonText: 'Create Portfolio',
        sub: 'Build an impressive portfolio easily',
        textColor: 'dark',
        titleColor: 'pink',
        link: '#offer',
        buttonColor: 'theme',
      },
    ],
  },
  features: {
    title: 'Exclusive Features for Designers',
    subtitle: 'Tools and resources to boost your creative journey',
    feature: {
      icon: ideaIcon,
      title: 'Collaboration Tools',
      description: 'Work seamlessly with clients and other designers',
      sub: 'Enhance your workflow with integrated tools',
      image: featuresPic,
    },
  },
  ctaOne: {
    bg: 'cta-d',
    text: 'Ready to showcase your talent?',
    buttonText: 'Sign Up Now',
  },
  ctaTwo: {
    bg: 'cta-d',
    text: 'Learn More About How CraftFlow Empowers Designers',
    buttonText: 'Discover More',
  },
  tour: {
    video: 'Video',
    buttonText: 'Contact Us',
    title: 'Virtual Tour',
    subtitle:
      'Get a sneak peek of CraftFlow in action – Experience the future of design!',
    cover: cover,
  },
  join: {
    link: '#offer',
    title: 'Join the CraftFlow Community',
    subtitle:
      'Engage with our interactive challenges and quizzes designed for creative minds',
    showcases: [
      {
        title: 'Designers Showcase',
        items: 'designers',
        showAllLink: '/',
      },
      {
        title: 'Latest Agencies Creations',
        items: 'agencies',
        showAllLink: '/',
      },
      {
        title: 'AI Smartly Crafted',
        items: 'ai',
        showAllLink: '/',
      },
    ],
    action: {
      color: 'dark',
      back: 'pink',
      heading:
        'Join the ranks of thousands of designers who are eagerly waiting for CraftFlow',
      text: 'Join now to CraftFlow',
    },
  },
  faq: {
    title: 'Frequently Asked Questions',
    subtitle: 'Find answers to common queries from designers',
    questions: [
      {
        question: 'How do I create a portfolio on CraftFlow?',
        answer:
          'Once you sign up, you can easily create and manage your portfolio from your dashboard',
      },
      {
        question: 'Can I collaborate with other designers on projects?',
        answer:
          'Yes, CraftFlow offers collaboration tools to work with other designers seamlessly',
      },
    ],
  },
  offer: {
    link: 'https://form.typeform.com/to/P9Re7Stt?kind=design&page=For Designers&button=Get Started',
    title: 'Special Offers for Designers',
    subtitle: 'Get access to premium features and resources',
    description: 'Access premium services and dedicated support',
    buttonText: 'Get Started',
  },
  footer: {
    organization: 'Public Organization "DVOSTOK"',
    registrationNumber: 'Registration Number: 44740443',
    links: [
      { href: '/privacy-policy', text: 'Privacy Policy' },
      { href: '/terms-of-use', text: 'Terms of Use' },
      { href: '/cookie-policy', text: 'Cookie Policy' },
    ],
    copyright: `Copyright © ${new Date().getFullYear()} CraftFlow. All rights reserved.`,
  },
};

export { forDesigners };

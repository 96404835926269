import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/parts/Header';
import { Footer } from '../components/parts/Footer';
import { privacy } from '../content/privacy';

const Privacy = () => {
  const { meta } = privacy;
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        {meta.canonical && <link rel='canonical' href={meta.canonical} />}
        {meta.ogTitle && <meta property='og:title' content={meta.ogTitle} />}
        {meta.ogDescription && (
          <meta property='og:description' content={meta.ogDescription} />
        )}
        {meta.ogImage && <meta property='og:image' content={meta.ogImage} />}
        {meta.twitterCard && (
          <meta name='twitter:card' content={meta.twitterCard} />
        )}
        {meta.twitterTitle && (
          <meta name='twitter:title' content={meta.twitterTitle} />
        )}
        {meta.twitterDescription && (
          <meta name='twitter:description' content={meta.twitterDescription} />
        )}
        {meta.twitterImage && (
          <meta name='twitter:image' content={meta.twitterImage} />
        )}
      </Helmet>
      <Header content={privacy.header} />
      <section className='page'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10 ma'>
              <div className='s-100'></div>
              <h1>Privacy Policy</h1>
              <p className='py-2'>Effective Date: November 28, 2024</p>
              <div className='s-20'></div>
              <h2>1. Introduction</h2>
              <p className='py-2'>
                Welcome to CraftFlow. This Privacy Policy outlines how we
                collect, use, and safeguard your personal information when you
                access our platform. By using our services, you agree to the
                terms outlined here.
              </p>

              <h2>2. Information We Collect</h2>
              <p className='py-2'>
                We collect information to enhance your experience on our
                platform:
              </p>
              <ul>
                <li>
                  <strong>Personal Information:</strong> Name, email address,
                  phone number, and other contact details when you register or
                  interact with us.
                </li>
                <li>
                  <strong>Usage Data:</strong> Information on how you use the
                  site, such as IP address, browser type, visited pages, and
                  time of visit.
                </li>
                <li>
                  <strong>Feedback and Suggestions:</strong> Details you provide
                  when sharing your thoughts to help us tailor CraftFlow to your
                  creative journey.
                </li>
              </ul>

              <h2>3. How We Use Your Information</h2>
              <p className='py-2'>
                Your data helps us provide and improve our services:
              </p>
              <ul>
                <li>Enhancing the functionality of the platform.</li>
                <li>Customizing the user experience based on your needs.</li>
                <li>Communicating updates, offers, and insights.</li>
                <li>Providing customer support and addressing inquiries.</li>
              </ul>

              <h2>4. Sharing of Information</h2>
              <p className='py-2'>
                We do not sell or share your personal data with third parties
                except under the following circumstances:
              </p>
              <ul>
                <li>When required by law or government authorities.</li>
                <li>
                  With trusted service providers who assist us in operating our
                  platform.
                </li>
                <li>
                  To protect the rights and safety of CraftFlow and its users.
                </li>
              </ul>

              <h2>5. Data Retention</h2>
              <p className='py-2'>
                We retain your data as long as necessary to provide our services
                or as required by law. You may request deletion of your data by
                contacting us.
              </p>

              <h2>6. Cookies and Tracking</h2>
              <p className='py-2'>
                We use cookies and similar tracking technologies to enhance your
                experience. Cookies help us understand user behavior and improve
                site performance. You can control cookie preferences through
                your browser settings.
              </p>

              <h2>7. Security</h2>
              <p className='py-2'>
                We implement robust measures to protect your data from
                unauthorized access, alteration, or disclosure. However, no
                method of transmission over the internet is entirely secure, and
                we cannot guarantee absolute security.
              </p>

              <h2>8. Your Rights</h2>
              <p className='py-2'>
                You have rights concerning your personal data:
              </p>
              <ul>
                <li>Access your data and request corrections.</li>
                <li>Request data deletion, subject to legal obligations.</li>
                <li>
                  Object to the processing of your data for certain purposes.
                </li>
              </ul>
              <p className='py-2'>
                To exercise these rights, contact us at the details provided
                below.
              </p>

              <h2>9. Changes to This Policy</h2>
              <p className='py-2'>
                We may update this Privacy Policy from time to time. Changes
                will be reflected on this page with a new effective date. We
                encourage you to review this page regularly for updates.
              </p>

              <h2>10. Contact Us</h2>
              <p className='py-2'>
                If you have any questions or concerns about this Privacy Policy,
                please contact us:
              </p>
              <ul>
                <li>
                  <strong>Email:</strong> support@craftflow.art
                </li>
                <li>
                  <strong>Address:</strong> Public Organization "DVOSTOK",
                  Registration Number: 44740443
                </li>
              </ul>

              <p className='py-2'>
                Thank you for trusting CraftFlow with your design journey!
              </p>
              <div className='s-100'></div>
            </div>
          </div>
        </div>
      </section>
      <Footer content={privacy.footer} />
    </>
  );
};

export { Privacy };

import React from 'react'
import { Container } from 'react-bootstrap'

const Title = (props) => {
  return (
    <Container className='tc'>
      <div className='s-100'></div>
      <div className='s-20'></div>
      <h3 className={`title-section c-${props.color}`}>{props.title}</h3>
      <h4 className={`title-section-sub my-3 w-${props.width} ma c-${props.sub}`}>{props.subtitle}</h4>
      <div className='s-40'></div>
    </Container>
  )
}

export { Title }

import React from 'react';
import { Container } from 'react-bootstrap';
import { Title } from '../elements/Title';
import { Action } from '../elements/Action';

import { Slideshow } from '../elements/Slideshow';
import designers from '../data/designers';
import agencies from '../data/agencies';
import ai from '../data/ai';
import aii from '../data/aii';

const Join = ({ content }) => {
  return (
    <Container className='tc'>
      <Title
        color='dark'
        sub='grey'
        title={content.title}
        subtitle={content.subtitle}
      />
      <div className='row'>
        <div className='col-lg-12'>
          {content.showcases.map((showcase, index) => (
            <React.Fragment key={index}>
              <div className='s-40'></div>
              <div className='showcase flex jcsb aic'>
                <h3 className='title-creator c-dark upp'>{showcase.title}</h3>
                <a
                  href={showcase.showAllLink}
                  className='title-creator c-grey upp'
                >
                  show all
                </a>
              </div>
              <div className='s-32'></div>
              <Slideshow ai={false}>
                {(showcase.items === 'designers'
                  ? designers
                  : showcase.items === 'agencies'
                  ? agencies
                  : showcase.items === 'ai'
                  ? ai.concat(aii)
                  : []
                ).map((e) => (
                  <div
                    className='item'
                    href={e.link}
                    aria-label={e.title}
                    target='blank'
                    key={e.id}
                  >
                    <div className='item-content tc flex aic jcc'>
                      <div className='m-5'>
                        <img
                          src={e.avatar}
                          alt={e.name}
                          width={48}
                          height={48}
                          className='r-24 ma mb-3'
                        />
                        <h4 className='title-name c-dark'>{e.name}</h4>
                        <div className='s-20'></div>
                        <h3 className='title-item c-dark'>{e.title}</h3>
                        <div className='s-20'></div>
                        <a
                          href={e.link}
                          className='button small bg-pink c-white'
                        >
                          View
                        </a>
                      </div>
                    </div>
                    <img
                      width={'100%'}
                      height={400}
                      src={e.pic}
                      alt={e.title}
                      className='work'
                    />
                  </div>
                ))}
              </Slideshow>
            </React.Fragment>
          ))}
        </div>
      </div>
      <Action
        color={content.action.color}
        back={content.action.back}
        heading={content.action.heading}
        text={content.action.text}
        link={content.link}
      />
      <div className='s-120'></div>
    </Container>
  );
};

export { Join };

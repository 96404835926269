import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/parts/Header';
import { Intro } from '../components/parts/Intro';
import { Footer } from '../components/parts/Footer';
import { thankyou } from '../content/thankyou';

const ThankYou = () => {
  const { meta } = thankyou;
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        {meta.canonical && <link rel='canonical' href={meta.canonical} />}
        {meta.ogTitle && <meta property='og:title' content={meta.ogTitle} />}
        {meta.ogDescription && (
          <meta property='og:description' content={meta.ogDescription} />
        )}
        {meta.ogImage && <meta property='og:image' content={meta.ogImage} />}
        {meta.twitterCard && (
          <meta name='twitter:card' content={meta.twitterCard} />
        )}
        {meta.twitterTitle && (
          <meta name='twitter:title' content={meta.twitterTitle} />
        )}
        {meta.twitterDescription && (
          <meta name='twitter:description' content={meta.twitterDescription} />
        )}
        {meta.twitterImage && (
          <meta name='twitter:image' content={meta.twitterImage} />
        )}
        <script>
          {`
            gtag('event', 'conversion', {
                'send_to': 'AW-16612831083/mL3BCJLgic4ZEOvezvE9',
                'value': 3.0,
                'currency': 'USD'
            });
          `}
        </script>
      </Helmet>
      <Header content={thankyou.header} />
      <Intro content={thankyou.intro} />
      <Footer content={thankyou.footer} />
    </>
  );
};

export { ThankYou };

import pic001 from '../../assets/images/works/aii/ai-1.webp'
import pic002 from '../../assets/images/works/aii/ai-2.webp'
import pic003 from '../../assets/images/works/aii/ai-3.webp'
import pic004 from '../../assets/images/works/aii/ai-4.webp'


import avatar from '../../assets/images/authors/ai.webp'

const data = [
  {
    id: 1,
    avatar: avatar,
    link: 'https://openai.com/dall-e-3',
    name: 'AI',
    title: 'Expanding Spectrum Phone',
    pic: pic001
  },
  {
    id: 2,
    avatar: avatar,
    link: 'https://openai.com/dall-e-3',
    name: 'AI',
    title: 'Yellow-Toned Pizza App Screens',
    pic: pic002
  },
  {
    id: 3,
    avatar: avatar,
    link: 'https://openai.com/dall-e-3',
    name: 'AI',
    title: 'Burning Pixels Behind Bright Headgear',
    pic: pic003
  },
  {
    id: 4,
    avatar: avatar,
    link: 'https://openai.com/dall-e-3',
    name: 'AI',
    title: 'Vibrant Color Waves',
    pic: pic004
  }
]

export default data

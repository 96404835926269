import pic001 from '../../assets/images/works/designers/work-1.webp'
import pic002 from '../../assets/images/works/designers/work-2.webp'
import pic003 from '../../assets/images/works/designers/work-3.webp'
import pic004 from '../../assets/images/works/designers/work-4.webp'
import pic005 from '../../assets/images/works/designers/work-5.webp'

import avatar001 from '../../assets/images/authors/designers/mike.webp'

const data = [
  {
    id: 1,
    avatar: avatar001,
    link: 'https://dribbble.com/shots/4429342/attachments/4429342-Milk-Chocolate-CUBIES',
    name: 'Mike | Creative Mints',
    title: 'Milk Chocolate / CUBIES',
    pic: pic001
  },
  {
    id: 2,
    avatar: avatar001,
    link: 'https://dribbble.com/shots/2843235-Business-Card-Creative-Mints',
    name: 'Mike | Creative Mints',
    title: 'Business Card / Creative Mints',
    pic: pic002
  },
  {
    id: 3,
    avatar: avatar001,
    link: 'https://dribbble.com/shots/5552234-Logo-Collection-Part-2',
    name: 'Mike | Creative Mints',
    title: 'Logo Collection / Part 2',
    pic: pic003
  },
  {
    id: 4,
    avatar: avatar001,
    link: 'https://dribbble.com/shots/1208110-Ui-Kit-Music',
    name: 'Mike | Creative Mints',
    title: 'Ui Kit (Music)',
    pic: pic004
  },
  {
    id: 5,
    avatar: avatar001,
    link: 'https://dribbble.com/shots/1318406-Bakery',
    name: 'Mike | Creative Mints',
    title: 'Bakery',
    pic: pic005
  }
]

export default data

import React from 'react';

const Button = (props) => {
  return (
    <a
      href={props.link}
      target={props.text === 'Sign Up for Early Access' ? 'blank' : '_self'}
      className={`button bg-${props.color} ${props.size} c-white`}
    >
      {props.text}
    </a>
  );
};

export { Button };

import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/parts/Header';
import { Intro } from '../components/parts/Intro';
import { Footer } from '../components/parts/Footer';
import { error } from '../content/error';

const Error = () => {
  const { meta } = error;
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        {meta.canonical && <link rel='canonical' href={meta.canonical} />}
        {meta.ogTitle && <meta property='og:title' content={meta.ogTitle} />}
        {meta.ogDescription && (
          <meta property='og:description' content={meta.ogDescription} />
        )}
        {meta.ogImage && <meta property='og:image' content={meta.ogImage} />}
        {meta.twitterCard && (
          <meta name='twitter:card' content={meta.twitterCard} />
        )}
        {meta.twitterTitle && (
          <meta name='twitter:title' content={meta.twitterTitle} />
        )}
        {meta.twitterDescription && (
          <meta name='twitter:description' content={meta.twitterDescription} />
        )}
        {meta.twitterImage && (
          <meta name='twitter:image' content={meta.twitterImage} />
        )}
      </Helmet>
      <Header content={error.header} />
      <Intro content={error.intro} />
      <Footer content={error.footer} />
    </>
  );
};

export { Error };

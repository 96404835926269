import React from 'react';
import { Container } from 'react-bootstrap';
import { Title } from '../elements/Title';
import { Card } from '../cards/Card';
import icon from '../../assets/images/icons/offer.webp';

const Offer = ({ content }) => {
  return (
    <section id='offer'>
      <Container className='tc'>
        <Title
          color='dark'
          sub='grey'
          title={content.title}
          subtitle={content.subtitle}
        />
        <Card
          back='offer'
          sub={content.description}
          icon={icon}
          width={64}
          height={64}
          title='CraftFlow'
          desc={content.key ? 'Logo Design Contest' : 'Early Access'}
          link={content.link}
          text={content.buttonText}
          textColor='white'
          titleColor='yellow'
          buttonColor='orange'
        />
        <div className='s-120'></div>
      </Container>
    </section>
  );
};

export { Offer };

import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = ({ content }) => {
  return (
    <footer className='bg-light'>
      <Container>
        <div className='s-60'></div>
        <div className='flex jcsb aic px-2'>
          <p className='c-grey my-0 py-0'>
            {content.organization}
            <br />
            {content.registrationNumber}
          </p>
          <div className='menu-footer'>
            {content.links.map((link, index) => (
              <React.Fragment key={index}>
                <a href={link.href} className='c-grey'>
                  {link.text}
                </a>
                {index < content.links.length - 1 && (
                  <span className='px-3 c-grey'>|</span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className='s-60'></div>
      </Container>
      <p className='c-grey my-0 py-4 tc border-top'>{content.copyright}</p>
    </footer>
  );
};

export { Footer };

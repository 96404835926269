import icon1 from '../assets/images/icons/ab-1.webp';
import pic1 from '../assets/images/about-b.webp';
import cover from '../assets/images/cover.webp';
import bfIcon from '../assets/svg/bf-icon.svg';
import featuresPic from '../assets/images/bs-feat.webp';

const forBusiness = {
  meta: {
    title: 'CraftFlow | For Businesses',
    description:
      'Connect with top designers and agencies to bring your brand to life. Join CraftFlow to access premium design services',
    canonical: 'https://example.com/for-business',
  },
  header: {
    joinNowText: 'Join Now',
    siteTitle: 'CraftFlow',
    lang: 'EN',
    login: 'Login',
    navLinks: [
      { href: '/for-designers', text: 'For Designers' },
      { href: '/for-agencies', text: 'For Agencies' },
      { href: '/for-business', text: 'For Business' },
      { href: '/design-contest', text: 'Design Contest' },
    ],
  },
  intro: {
    bg: 'forbusiness',
    title: 'For Businesses',
    subtitle: 'Elevate Your Brand with CraftFlow',
    buttonText: 'Join as a Business',
  },
  about: {
    title: 'Why CraftFlow?',
    subtitle:
      'CraftFlow connects you with the best designers and agencies to meet your business needs',
    sections: [
      {
        id: 2,
        icon: icon1,
        image: pic1,
        title: 'Access Top Talent',
        description: 'Find designers and agencies that align with your brand',
        buttonText: 'Find Talent',
        sub: 'Collaborate with professionals to achieve your vision',
        textColor: 'dark',
        titleColor: 'pink',
        link: '#offer',
        buttonColor: 'theme',
      },
    ],
  },
  features: {
    title: 'Business Solutions',
    subtitle: 'Comprehensive tools and services tailored for businesses',
    feature: {
      icon: bfIcon,
      title: 'Brand Development',
      description: 'Work with experts to build and enhance your brand',
      sub: 'Strategize and execute with confidence',
      image: featuresPic,
    },
  },
  ctaOne: {
    bg: 'cta-b',
    text: 'Ready to transform your business?',
    buttonText: 'Get Started',
  },
  ctaTwo: {
    bg: 'cta-b',
    text: 'Discover How CraftFlow Can Boost Your Business',
    buttonText: 'Learn More',
  },
  tour: {
    video: 'Video',
    buttonText: 'Contact Us',
    title: 'Platform Overview',
    subtitle: 'See how CraftFlow can support your business objectives',
    cover: cover,
  },
  join: {
    link: '#offer',
    title: 'Join the CraftFlow Network',
    subtitle: 'Collaborate with creatives to drive your business forward',
    showcases: [
      {
        title: 'Designers Showcase',
        items: 'designers',
        showAllLink: '/',
      },
      {
        title: 'Latest Agencies Creations',
        items: 'agencies',
        showAllLink: '/',
      },
      {
        title: 'AI Smartly Crafted',
        items: 'ai',
        showAllLink: '/',
      },
    ],
    action: {
      color: 'dark',
      back: 'pink',
      heading: 'Become part of a network that drives innovation and growth',
      text: 'Join Now',
    },
  },
  faq: {
    title: 'Frequently Asked Questions',
    subtitle: 'Find answers to common queries from businesses',
    questions: [
      {
        question: 'How do I hire designers or agencies on CraftFlow?',
        answer:
          'You can browse profiles and portfolios to find the right fit, then reach out directly to discuss your project',
      },
      {
        question: 'What services are available for businesses?',
        answer:
          'We offer a range of services including branding, web design, marketing materials, and more',
      },
    ],
  },
  offer: {
    link: 'https://form.typeform.com/to/P9Re7Stt?kind=brand&page=For Business&button=Get Started',
    title: 'Exclusive Business Offers',
    subtitle: 'Unlock premium features and priority support',
    description: 'Access premium services and dedicated support',
    buttonText: 'Get Started',
  },
  footer: {
    organization: 'Public Organization "DVOSTOK"',
    registrationNumber: 'Registration Number: 44740443',
    links: [
      { href: '/privacy-policy', text: 'Privacy Policy' },
      { href: '/terms-of-use', text: 'Terms of Use' },
      { href: '/cookie-policy', text: 'Cookie Policy' },
    ],
    copyright: `Copyright © ${new Date().getFullYear()} CraftFlow. All rights reserved.`,
  },
};
export { forBusiness };

const privacy = {
  meta: {
    title: 'CraftFlow | Privacy Policy',
    description:
      'Welcome to CraftFlow - Where Creativity Meets Innovation. Explore our tools and join a vibrant community of designers, agencies, and brands',
    canonical: 'https://craftflow.art/',
    ogTitle: 'CraftFlow | Privacy Policy',
    ogDescription:
      'CraftFlow empowers designers, agencies, and brands with unmatched tools and community support',
    ogImage: '/presentation.webp',
    twitterCard: 'summary_large_image',
    twitterTitle: 'CraftFlow | Privacy Policy',
    twitterDescription:
      'Welcome to CraftFlow - Where Creativity Meets Innovation',
    twitterImage: '/presentation.webp',
  },
  header: {
    joinNowText: 'Join Now',
    siteTitle: 'CraftFlow',
    lang: 'EN',
    login: 'Login',
    navLinks: [
      { href: '/for-designers', text: 'For Designers' },
      { href: '/for-agencies', text: 'For Agencies' },
      { href: '/for-business', text: 'For Business' },
      { href: '/design-contest', text: 'Design Contest' },
    ],
  },
  footer: {
    organization: 'Public Organization "DVOSTOK"',
    registrationNumber: 'Registration Number: 44740443',
    links: [
      { href: '/privacy-policy', text: 'Privacy Policy' },
      { href: '/terms-of-use', text: 'Terms of Use' },
      { href: '/cookie-policy', text: 'Cookie Policy' },
    ],
    copyright: `Copyright © ${new Date().getFullYear()} CraftFlow. All rights reserved.`,
  },
};

export { privacy };

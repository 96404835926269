import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Contest } from './pages/Contest';
import { ForAgencies } from './pages/ForAgencies';
import { ForDesigners } from './pages/ForDesigners';
import { ForBusiness } from './pages/ForBusiness';
import { ThankYou } from './pages/ThankYou';
import { Privacy } from './pages/Privacy';
import { Terms } from './pages/Terms';
import { Cookie } from './pages/Cookie';
import { Error } from './pages/Error';

import './styles/custom.sass';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='for-designers' element={<ForDesigners />} />
        <Route path='for-agencies' element={<ForAgencies />} />
        <Route path='for-business' element={<ForBusiness />} />
        <Route path='thank-you' element={<ThankYou />} />
        <Route path='design-contest' element={<Contest />} />
        <Route path='cookie-policy' element={<Cookie />} />
        <Route path='privacy-policy' element={<Privacy />} />
        <Route path='terms-of-use' element={<Terms />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export { App };

import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/parts/Header';
import { Intro } from '../components/parts/Intro';
import { About } from '../components/parts/About';
import { CtaOne } from '../components/parts/CtaOne';
import { Features } from '../components/parts/Features';
import { Tour } from '../components/parts/Tour';
import { Join } from '../components/parts/Join';
import { CtaTwo } from '../components/parts/CtaTwo';
import { FAQ } from '../components/parts/FAQ';
import { Offer } from '../components/parts/Offer';
import { Footer } from '../components/parts/Footer';
import { home } from '../content/home';

const Home = () => {
  const { meta } = home;
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        {meta.canonical && <link rel='canonical' href={meta.canonical} />}
        {meta.ogTitle && <meta property='og:title' content={meta.ogTitle} />}
        {meta.ogDescription && (
          <meta property='og:description' content={meta.ogDescription} />
        )}
        {meta.ogImage && <meta property='og:image' content={meta.ogImage} />}
        {meta.twitterCard && (
          <meta name='twitter:card' content={meta.twitterCard} />
        )}
        {meta.twitterTitle && (
          <meta name='twitter:title' content={meta.twitterTitle} />
        )}
        {meta.twitterDescription && (
          <meta name='twitter:description' content={meta.twitterDescription} />
        )}
        {meta.twitterImage && (
          <meta name='twitter:image' content={meta.twitterImage} />
        )}
      </Helmet>
      <Header content={home.header} />
      <Intro content={home.intro} />
      <About content={home.about} />
      <CtaOne content={home.ctaOne} />
      <Features content={home.features} />
      <Tour content={home.tour} />
      <Join content={home.join} />
      <CtaTwo content={home.ctaTwo} />
      <FAQ content={home.faq} />
      <Offer content={home.offer} />
      <Footer content={home.footer} />
    </>
  );
};

export { Home };

import React from 'react';
import { Button } from '../elements/Button';

const Intro = ({ content }) => {
  const url =
    'https://form.typeform.com/to/P9Re7Stt?kind=xxxxx&page=Main&button=';
  return (
    <div id='hero' className={content.bg}>
      <div className='h-156'></div>
      <div className='container tc'>
        <h2 className='title-intro c-white w-80 ma'>{content.title}</h2>
        <div className='s-12'></div>
        <h3 className='title-intro-sub c-white w-60 ma'>{content.subtitle}</h3>
        <div className='s-40'></div>
        {content.page === 'errorpage' || content.page === 'thankyoupage' ? (
          <Button color='pink' text={content.buttonText} link='/' />
        ) : (
          <Button
            color='pink'
            text={content.buttonText}
            link={
              content.bg === 'homepage' ? url + content.buttonText : '#offer'
            }
          />
        )}
      </div>
      <div className='h-156'></div>
    </div>
  );
};

export { Intro };

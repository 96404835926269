import React from 'react';
import { Container } from 'react-bootstrap';
import { Title } from '../elements/Title';
import { Card } from '../cards/Card';

const About = ({ content }) => {
  return (
    <Container className='tc'>
      <Title
        width={64}
        color='dark'
        sub='grey'
        title={content.title}
        subtitle={content.subtitle}
      />
      <div className='row g-0'>
        {content.sections.map((section) => (
          <React.Fragment key={section.id}>
            <div
              className='col-lg-6'
              style={{
                order: section.id === 1 ? 1 : 2,
              }}
            >
              <Card
                back='white'
                sub={section.sub}
                icon={section.icon}
                width={61}
                height={64}
                title={section.title}
                desc={section.description}
                link={section.link}
                text={section.buttonText}
                textColor={section.textColor}
                titleColor={section.titleColor}
                buttonColor={section.buttonColor}
              />
            </div>
            <div
              className='col-lg-6'
              style={{
                order: section.id === 1 ? 2 : 1,
              }}
            >
              <img
                src={section.image}
                alt={section.description}
                width={'100%'}
                height={480}
                className='obfc'
              />
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className='s-120'></div>
    </Container>
  );
};

export { About };

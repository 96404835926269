import pic001 from '../../assets/images/works/agencies/work-1.webp'
import pic002 from '../../assets/images/works/agencies/work-2.webp'
import pic003 from '../../assets/images/works/agencies/work-3.webp'
import pic004 from '../../assets/images/works/agencies/work-4.webp'
import pic005 from '../../assets/images/works/agencies/work-5.webp'

import avatar001 from '../../assets/images/authors/agencies/sajon.webp'
import avatar002 from '../../assets/images/authors/agencies/tran.webp'
import avatar003 from '../../assets/images/authors/agencies/mateusz.webp'
import avatar004 from '../../assets/images/authors/agencies/akash.webp'
import avatar005 from '../../assets/images/authors/agencies/ofspace.webp'

const data = [
  {
    id: 1,
    avatar: avatar001,
    link: 'https://dribbble.com/shots/18300406-Affitto-Real-Estate-App',
    name: 'Sajon for Orix Creative',
    title: 'Affitto Real Estate App',
    pic: pic001
  },
  {
    id: 2,
    avatar: avatar002,
    link: 'https://dribbble.com/shots/14180899-Folio-Agency-Mobile-Version',
    name: 'Tran Mau Tri Tam ✪ for UI8',
    title: 'Folio Agency – Mobile Version',
    pic: pic002
  },
  {
    id: 3,
    avatar: avatar003,
    link: 'https://dribbble.com/shots/6415595-Unhotel',
    name: 'Mateusz Madura for Seahawk',
    title: 'Unhotel 🏕',
    pic: pic003
  },
  {
    id: 4,
    avatar: avatar004,
    link: 'https://dribbble.com/shots/16920905-Creative-Digital-Agency',
    name: 'Akash Das',
    title: 'Creative Digital Agency',
    pic: pic004
  },
  {
    id: 5,
    avatar: avatar005,
    link: 'https://dribbble.com/shots/10215585-Ofspace-Web-Designs',
    name: 'Ofspace',
    title: 'Ofspace Web Designs',
    pic: pic005
  }
]

export default data

import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/parts/Header';
import { Intro } from '../components/parts/Intro';
import { About } from '../components/parts/About';
import { CtaOne } from '../components/parts/CtaOne';
import { Features } from '../components/parts/Features';
import { CtaTwo } from '../components/parts/CtaTwo';
import { FAQ } from '../components/parts/FAQ';
import { Offer } from '../components/parts/Offer';
import { Footer } from '../components/parts/Footer';
import { contest } from '../content/contest';

const Contest = () => {
  const { meta } = contest;
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        {meta.canonical && <link rel='canonical' href={meta.canonical} />}
      </Helmet>
      <Header content={contest.header} />
      <Intro content={contest.intro} />
      <About content={contest.about} />
      <CtaOne content={contest.ctaOne} />
      <Features content={contest.features} />
      <CtaTwo content={contest.ctaTwo} />
      <FAQ content={contest.faq} />
      <Offer content={contest.offer} />
      <Footer content={contest.footer} />
    </>
  );
};

export { Contest };

import React from 'react';
import { Container } from 'react-bootstrap';
import { Title } from '../elements/Title';
import { Action } from '../elements/Action';

const Tour = ({ content }) => {
  const url =
    'https://form.typeform.com/to/P9Re7Stt?kind=xxxxx&page=Main&button=';
  return (
    <section className='tour'>
      <Container className='tc'>
        <Title
          width={40}
          color='white'
          sub='white'
          title={content.title}
          subtitle={content.subtitle}
        />
        <a
          href={content.page ? url + content.video : '#offer'}
          target={content.page ? 'blank' : '_self'}
        >
          <img
            src={content.cover}
            alt='youtube'
            width='100%'
            height='100%'
            className='w-100 obfc'
          />
        </a>
        <Action
          color='white'
          back='pink'
          link={content.page ? url + content.buttonText : '#offer'}
          heading='Help us tailor CraftFlow to your creative journey'
          text={content.buttonText}
        />
        <div className='s-120'></div>
      </Container>
    </section>
  );
};

export { Tour };

import icon1 from '../assets/images/icons/ab-1.webp';
import icon2 from '../assets/images/icons/ab-2.webp';
import pic1 from '../assets/images/pic-ab-1.webp';
import pic2 from '../assets/images/pic-ab-2.webp';
import cover from '../assets/images/cover.webp';
import ideaIcon from '../assets/images/icons/idea.webp';
import featuresPic from '../assets/images/features.webp';

const home = {
  meta: {
    title: 'CraftFlow | Home',
    description:
      'Welcome to CraftFlow - Where Creativity Meets Innovation. Explore our tools and join a vibrant community of designers, agencies, and brands',
    canonical: 'https://craftflow.art/',
    ogTitle: 'CraftFlow | Home',
    ogDescription:
      'CraftFlow empowers designers, agencies, and brands with unmatched tools and community support',
    ogImage: '/presentation.webp',
    twitterCard: 'summary_large_image',
    twitterTitle: 'CraftFlow | Home',
    twitterDescription:
      'Welcome to CraftFlow - Where Creativity Meets Innovation',
    twitterImage: '/presentation.webp',
  },
  header: {
    page: 'homepage',
    joinNowText: 'Join Now',
    siteTitle: 'CraftFlow',
    lang: 'EN',
    login: 'Login',
    navLinks: [
      { href: '/for-designers', text: 'For Designers' },
      { href: '/for-agencies', text: 'For Agencies' },
      { href: '/for-business', text: 'For Business' },
      { href: '/design-contest', text: 'Design Contest' },
    ],
  },
  intro: {
    bg: 'homepage',
    title: 'Join CraftFlow & Revolutionize Your Design Journey!',
    subtitle: 'Discover exclusive features tailored for creative minds',
    buttonText: 'Sign Up for Early Access',
  },
  about: {
    title: 'About the Platform',
    subtitle:
      'CraftFlow – Where Creativity Meets Innovation. Our mission is to empower designers with unmatched tools & community support',
    sections: [
      {
        id: 1,
        icon: icon1,
        image: pic1,
        title: 'CraftFlow',
        description: "More than a platform, it's a creative partner",
        buttonText: 'Become our partner',
        link: '/for-agencies',
        sub: 'From real-time collaboration to state-of-the-art design tools',
        textColor: 'dark',
        titleColor: 'pink',
        buttonColor: 'theme',
      },
      {
        id: 2,
        icon: icon2,
        image: pic2,
        title: 'CraftFlow',
        description: 'The new home for your design aspirations',
        buttonText: 'Join CraftFlow',
        link: '/for-designers',
        sub: 'Endorsed by top designers and creative minds',
        textColor: 'dark',
        titleColor: 'theme',
        buttonColor: 'pink',
      },
    ],
  },
  features: {
    title: 'Features & Benefits',
    subtitle:
      'Each feature of CraftFlow, from our intuitive interface to advanced analytics, is crafted to enhance your design experience',
    feature: {
      icon: ideaIcon,
      title: 'CraftFlow',
      description: 'All your needs for efficiency',
      sub: 'Collaboration & Exposure in the Ever-Evolving Design World',
      image: featuresPic,
    },
  },
  ctaOne: {
    page: 'homepage',
    bg: 'cta-h',
    text: 'Ready to elevate your design experience?',
    buttonText: 'Join our waitlist today!',
  },
  ctaTwo: {
    page: 'homepage',
    bg: 'cta-h',
    text: 'Learn More About CraftFlow Revolutionary Features',
    buttonText: 'Learn more',
  },
  tour: {
    page: 'homepage',
    video: 'Video',
    buttonText: 'Share your thoughts',
    title: 'Virtual Tour',
    subtitle:
      'Get a sneak peek of CraftFlow in action – Experience the future of design!',
    cover: cover,
  },
  join: {
    link: '/for-designers',
    title: 'Join the CraftFlow Community',
    subtitle:
      'Engage with our interactive challenges and quizzes designed for creative minds',
    showcases: [
      {
        title: 'Designers Showcase',
        items: 'designers',
        showAllLink: '/',
      },
      {
        title: 'Latest Agencies Creations',
        items: 'agencies',
        showAllLink: '/',
      },
      {
        title: 'AI Smartly Crafted',
        items: 'ai',
        showAllLink: '/',
      },
    ],
    action: {
      color: 'dark',
      back: 'pink',
      heading:
        'Join the ranks of thousands of designers who are eagerly waiting for CraftFlow',
      text: 'Join now to CraftFlow',
    },
  },
  faq: {
    title: 'Frequently Asked Questions',
    subtitle: 'Have questions? Find answers to common queries about CraftFlow',
    questions: [
      {
        question:
          'What design solutions are included in your fixed-fee service for businesses?',
        answer:
          'Our fixed-fee service includes a comprehensive suite of design solutions tailored to your business needs',
      },
      {
        question:
          'How long does it typically take to create design solutions after payment?',
        answer:
          'Typically, it takes 2-4 weeks to deliver initial design concepts after payment is received',
      },
      {
        question: 'Does the fixed fee include consultation support?',
        answer:
          'Yes, consultation support is included to ensure the designs align with your vision',
      },
      {
        question: 'Is there a time limit for the duration of the fixed fee?',
        answer:
          'The fixed fee covers the project from inception to final delivery, with no strict time limit',
      },
    ],
  },
  offer: {
    link: '/for-business',
    title: 'Special Offers & Initiatives',
    subtitle:
      'Be the first to explore new features and enjoy exclusive benefits',
    description: 'Unlock premium features and priority support',
    buttonText: 'Learn More',
  },
  footer: {
    organization: 'Public Organization "DVOSTOK"',
    registrationNumber: 'Registration Number: 44740443',
    links: [
      { href: '/privacy-policy', text: 'Privacy Policy' },
      { href: '/terms-of-use', text: 'Terms of Use' },
      { href: '/cookie-policy', text: 'Cookie Policy' },
    ],
    copyright: `Copyright © ${new Date().getFullYear()} CraftFlow. All rights reserved.`,
  },
};

export { home };

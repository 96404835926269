import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/parts/Header';
import { Footer } from '../components/parts/Footer';
import { cookie } from '../content/cookie';

const Cookie = () => {
  const { meta } = cookie;
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        {meta.canonical && <link rel='canonical' href={meta.canonical} />}
        {meta.ogTitle && <meta property='og:title' content={meta.ogTitle} />}
        {meta.ogDescription && (
          <meta property='og:description' content={meta.ogDescription} />
        )}
        {meta.ogImage && <meta property='og:image' content={meta.ogImage} />}
        {meta.twitterCard && (
          <meta name='twitter:card' content={meta.twitterCard} />
        )}
        {meta.twitterTitle && (
          <meta name='twitter:title' content={meta.twitterTitle} />
        )}
        {meta.twitterDescription && (
          <meta name='twitter:description' content={meta.twitterDescription} />
        )}
        {meta.twitterImage && (
          <meta name='twitter:image' content={meta.twitterImage} />
        )}
      </Helmet>
      <Header content={cookie.header} />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10 ma'>
              <div className='s-100'></div>
              <h1>Cookie Policy</h1>
              <p className='py-2'>Effective Date: November 28, 2024</p>

              <div className='s-20'></div>
              <h2>1. Introduction</h2>
              <p className='py-2'>
                At CraftFlow, we use cookies and similar tracking technologies
                to enhance your experience on our platform. This Cookie Policy
                explains what cookies are, how we use them, and how you can
                manage your preferences.
              </p>

              <h2>2. What Are Cookies?</h2>
              <p className='py-2'>
                Cookies are small text files stored on your device when you
                visit a website. They help websites remember your preferences,
                improve functionality, and gather analytics to enhance user
                experience.
              </p>

              <h2>3. Types of Cookies We Use</h2>
              <p className='py-2'>
                We use the following types of cookies on our platform:
              </p>
              <ul>
                <li>
                  <strong>Essential Cookies:</strong> These cookies are
                  necessary for the platform to function properly. They enable
                  core features such as user login and secure browsing.
                </li>
                <li>
                  <strong>Performance Cookies:</strong> These cookies help us
                  understand how users interact with the platform by collecting
                  information on pages visited, time spent, and errors
                  encountered.
                </li>
                <li>
                  <strong>Functional Cookies:</strong> These cookies remember
                  your preferences, such as language settings, to provide a
                  personalized experience.
                </li>
                <li>
                  <strong>Analytics and Marketing Cookies:</strong> These
                  cookies help us analyze user behavior and deliver targeted
                  advertisements that align with your interests.
                </li>
              </ul>

              <h2>4. How We Use Cookies</h2>
              <p className='py-2'>We use cookies to:</p>
              <ul>
                <li>Ensure the platform operates efficiently and securely.</li>
                <li>Remember your preferences and settings.</li>
                <li>
                  Analyze user behavior to improve our services and content.
                </li>
                <li>
                  Deliver personalized content and targeted advertisements.
                </li>
              </ul>

              <h2>5. Third-Party Cookies</h2>
              <p className='py-2'>
                We may allow third-party service providers, such as analytics
                and advertising partners, to set cookies on our platform. These
                cookies are subject to the respective privacy policies of these
                third parties.
              </p>

              <h2>6. Managing Cookies</h2>
              <p className='py-2'>
                You can manage your cookie preferences through your browser
                settings. Most browsers allow you to:
              </p>
              <ul>
                <li>View the cookies stored on your device.</li>
                <li>Delete all or specific cookies.</li>
                <li>Block cookies from specific websites.</li>
                <li>
                  Set your browser to notify you when cookies are being used.
                </li>
              </ul>
              <p className='py-2'>
                Note that disabling cookies may affect the functionality of the
                platform and limit your user experience.
              </p>

              <h2>7. Changes to This Cookie Policy</h2>
              <p className='py-2'>
                We may update this Cookie Policy from time to time to reflect
                changes in technology or legal requirements. Any updates will be
                posted on this page with a new effective date.
              </p>

              <h2>8. Contact Us</h2>
              <p className='py-2'>
                If you have any questions or concerns about this Cookie Policy,
                please contact us:
              </p>
              <ul>
                <li>
                  <strong>Email:</strong> support@craftflow.art
                </li>
                <li>
                  <strong>Address:</strong> Public Organization "DVOSTOK",
                  Registration Number: 44740443
                </li>
              </ul>

              <p className='py-2'>
                Thank you for using CraftFlow and trusting us with your data!
              </p>
              <div className='s-100'></div>
            </div>
          </div>
        </div>
      </section>
      <Footer content={cookie.footer} />
    </>
  );
};

export { Cookie };

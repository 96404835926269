import React from 'react';
import { Container } from 'react-bootstrap';

const CtaOne = ({ content }) => {
  const url =
    'https://form.typeform.com/to/P9Re7Stt?kind=xxxxx&page=Main&button=';
  return (
    <section className={`h-100-v flex jcc aic cta-1 ${content.bg}`}>
      <Container className='tc'>
        <h3 className='title-cta c-white w-60 ma'>{content.text}</h3>
        <div className='s-40'></div>
        <a
          aria-label={content.buttonText}
          href={content.page ? url + content.buttonText : '#offer'}
          className='button bg-pink c-white'
          target={content.page ? 'blank' : '_self'}
        >
          {content.buttonText}
        </a>
      </Container>
    </section>
  );
};

export { CtaOne };

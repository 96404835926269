import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/parts/Header';
import { Footer } from '../components/parts/Footer';
import { terms } from '../content/terms';

const Terms = () => {
  const { meta } = terms;
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        {meta.canonical && <link rel='canonical' href={meta.canonical} />}
        {meta.ogTitle && <meta property='og:title' content={meta.ogTitle} />}
        {meta.ogDescription && (
          <meta property='og:description' content={meta.ogDescription} />
        )}
        {meta.ogImage && <meta property='og:image' content={meta.ogImage} />}
        {meta.twitterCard && (
          <meta name='twitter:card' content={meta.twitterCard} />
        )}
        {meta.twitterTitle && (
          <meta name='twitter:title' content={meta.twitterTitle} />
        )}
        {meta.twitterDescription && (
          <meta name='twitter:description' content={meta.twitterDescription} />
        )}
        {meta.twitterImage && (
          <meta name='twitter:image' content={meta.twitterImage} />
        )}
      </Helmet>
      <Header content={terms.header} />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10 ma'>
              <div className='s-100'></div>
              <h1>Terms of Use</h1>
              <p className='py-2'>Effective Date: November 28, 2024</p>

              <div className='s-20'></div>
              <h2>1. Acceptance of Terms</h2>
              <p className='py-2'>
                Welcome to CraftFlow. By accessing or using our platform at{' '}
                <a href='https://craftflow.art/'>https://craftflow.art/</a>, you
                agree to comply with and be bound by these Terms of Use. If you
                do not agree, please do not use our platform.
              </p>

              <h2>2. Use of the Platform</h2>
              <p className='py-2'>
                The platform is designed to provide tools and resources for
                designers, agencies, and businesses. By using the platform, you
                agree to:
              </p>
              <ul>
                <li>Use the services solely for lawful purposes.</li>
                <li>
                  Refrain from sharing false, misleading, or inappropriate
                  content.
                </li>
                <li>
                  Respect intellectual property rights and avoid unauthorized
                  use of others' content.
                </li>
              </ul>

              <h2>3. User Accounts</h2>
              <p className='py-2'>
                To access certain features, you may be required to create an
                account. You agree to:
              </p>
              <ul>
                <li>
                  Provide accurate and up-to-date information during
                  registration.
                </li>
                <li>Maintain the confidentiality of your login credentials.</li>
                <li>
                  Notify us immediately of any unauthorized access to your
                  account.
                </li>
              </ul>

              <h2>4. Prohibited Activities</h2>
              <p className='py-2'>When using CraftFlow, you agree not to:</p>
              <ul>
                <li>
                  Engage in any activity that disrupts or interferes with the
                  platform's functionality.
                </li>
                <li>Upload viruses or malicious software.</li>
                <li>
                  Attempt to hack, exploit, or misuse the platform or other
                  users' accounts.
                </li>
              </ul>

              <h2>5. Intellectual Property</h2>
              <p className='py-2'>
                All content on CraftFlow, including text, graphics, logos, and
                software, is the property of CraftFlow or its licensors.
                Unauthorized use, reproduction, or distribution of any content
                is strictly prohibited.
              </p>

              <h2>6. User-Generated Content</h2>
              <p className='py-2'>
                Users may upload and share content on the platform. By doing so,
                you grant CraftFlow a non-exclusive, worldwide, royalty-free
                license to use, reproduce, and distribute your content for
                platform-related purposes.
              </p>
              <p className='py-2'>
                You are responsible for ensuring that your content does not
                violate any laws or third-party rights.
              </p>

              <h2>7. Limitation of Liability</h2>
              <p className='py-2'>
                CraftFlow is not liable for any direct, indirect, incidental, or
                consequential damages resulting from your use of the platform.
                This includes, but is not limited to, loss of data, revenue, or
                business opportunities.
              </p>

              <h2>8. Termination</h2>
              <p className='py-2'>
                We reserve the right to terminate or suspend your account and
                access to the platform at our discretion, without prior notice,
                if you violate these Terms of Use.
              </p>

              <h2>9. Modifications to the Terms</h2>
              <p className='py-2'>
                We may update these Terms of Use from time to time. Changes will
                be effective upon posting to the platform. Your continued use of
                the platform signifies your acceptance of the revised terms.
              </p>

              <h2>10. Governing Law</h2>
              <p className='py-2'>
                These Terms of Use are governed by and construed in accordance
                with the laws of [Your Jurisdiction]. Any disputes arising from
                these terms shall be resolved in the courts of [Your
                Jurisdiction].
              </p>

              <h2>11. Contact Us</h2>
              <p className='py-2'>
                If you have any questions about these Terms of Use, please
                contact us:
              </p>
              <ul>
                <li>
                  <strong>Email:</strong> support@craftflow.art
                </li>
                <li>
                  <strong>Address:</strong> Public Organization "DVOSTOK",
                  Registration Number: 44740443
                </li>
              </ul>

              <p className='py-2'>
                Thank you for using CraftFlow. We look forward to supporting
                your creative journey!
              </p>
              <div className='s-100'></div>
            </div>
          </div>
        </div>
      </section>
      <Footer content={terms.footer} />
    </>
  );
};

export { Terms };

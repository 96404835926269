import React from 'react';
import { Container } from 'react-bootstrap';
import logo from '../../logo.png';
import en from '../../assets/images/lang/en.svg';

const Header = ({ content }) => {
  const url =
    'https://form.typeform.com/to/P9Re7Stt?kind=xxxxx&page=Main&button=';

  return (
    <>
      <div className='w-100 flex aic jcc'>
        <Container>
          <div className='flex aic jcsb'>
            <div className='top-menu left'>
              <a
                href={content.page ? url + content.joinNowText : '#offer'}
                className='button outline small c-white'
                target={content.page ? 'blank' : '_self'}
              >
                {content.joinNowText}
              </a>
            </div>
            <h1 className='site-title'>
              <a href='/' aria-label='CraftFlow Logo'>
                <img
                  src={logo}
                  alt={content.siteTitle}
                  width={180}
                  height={22}
                  className='logotype'
                />
              </a>
            </h1>
            <div className='top-menu lang-area'>
              <a href='/' className='lang-link'>
                <img
                  src={en}
                  alt={content.lang}
                  width={16}
                  height={20}
                  className='pb-1'
                />
                <span className='lang-text'>{content.lang}</span>
              </a>
              <a
                href={content.page ? url + content.login : '#offer'}
                target={content.page ? 'blank' : '_self'}
              >
                {content.login}
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className='top-nav flex jcc aic'>
        {content.navLinks.map((link, index) => (
          <a key={index} href={link.href}>
            {link.text}
          </a>
        ))}
      </div>
    </>
  );
};

export { Header };

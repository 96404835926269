// src/pages/ForBusiness.js

import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/parts/Header';
import { Intro } from '../components/parts/Intro';
import { About } from '../components/parts/About';
import { CtaOne } from '../components/parts/CtaOne';
import { Features } from '../components/parts/Features';
import { Tour } from '../components/parts/Tour';
import { Join } from '../components/parts/Join';
import { CtaTwo } from '../components/parts/CtaTwo';
import { FAQ } from '../components/parts/FAQ';
import { Offer } from '../components/parts/Offer';
import { Footer } from '../components/parts/Footer';
import { forBusiness } from '../content/forBusiness';

const ForBusiness = () => {
  const { meta } = forBusiness;
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        {meta.canonical && <link rel='canonical' href={meta.canonical} />}
      </Helmet>
      <Header content={forBusiness.header} />
      <Intro content={forBusiness.intro} />
      <About content={forBusiness.about} />
      <CtaOne content={forBusiness.ctaOne} />
      <Features content={forBusiness.features} />
      <Tour content={forBusiness.tour} />
      <Join content={forBusiness.join} />
      <CtaTwo content={forBusiness.ctaTwo} />
      <FAQ content={forBusiness.faq} />
      <Offer content={forBusiness.offer} />
      <Footer content={forBusiness.footer} />
    </>
  );
};

export { ForBusiness };

import icon1 from '../assets/images/icons/ab-2.webp';
import pic1 from '../assets/images/about-c-2.webp';

import icon2 from '../assets/images/icons/ab-1.webp';
import pic2 from '../assets/images/about-c-1.webp';

import ideaIcon from '../assets/images/icons/top.webp';
import featuresPic from '../assets/images/features-с.webp';

const contest = {
  meta: {
    title: 'CraftFlow | Logo Design Contest',
    description:
      'Participate in the CraftFlow logo design contest and win a premium account for a year!',
    canonical: 'https://example.com/logo-design-contest',
  },
  header: {
    joinNowText: 'Join Now',
    siteTitle: 'CraftFlow',
    lang: 'EN',
    login: 'Login',
    navLinks: [
      { href: '/for-designers', text: 'For Designers' },
      { href: '/for-agencies', text: 'For Agencies' },
      { href: '/for-business', text: 'For Business' },
      { href: '/design-contest', text: 'Design Contest' },
    ],
  },
  intro: {
    bg: 'contest',
    title: 'Design the Icon of CraftFlow',
    subtitle:
      'Join our logo design contest and win a premium account for one year!',
    buttonText: 'Upload Your Logo',
  },
  about: {
    title: 'Why Participate?',
    subtitle:
      'Create an original icon for CraftFlow, showcase your skills, and compete for exclusive rewards',
    sections: [
      {
        id: 1,
        icon: icon1,
        image: pic1,
        title: 'Design the Icon',
        description:
          'Submit your logo design and have a chance to become the face of CraftFlow',
        buttonText: 'Upload Your Design',
        link: '#offer',
        sub: 'Create a unique icon that works in all sizes',
        textColor: 'dark',
        titleColor: 'pink',
        buttonColor: 'theme',
      },
      {
        id: 2,
        icon: icon2,
        image: pic2,
        title: 'Win a Premium Account',
        description:
          'The winner will receive a premium CraftFlow account for one year!',
        buttonText: 'Learn About Rewards',
        link: '#offer',
        sub: 'Take your design career to the next level with CraftFlow',
        textColor: 'dark',
        titleColor: 'pink',
        buttonColor: 'theme',
      },
    ],
  },
  features: {
    title: 'Contest Highlights',
    subtitle: 'What makes this contest exciting?',
    feature: {
      icon: ideaIcon,
      title: 'Professional Judging Panel',
      description: 'Your logo will be evaluated by industry experts',
      sub: 'Get constructive feedback from experienced professionals',
      image: featuresPic,
    },
  },
  ctaOne: {
    bg: 'cta-c',
    text: 'Ready to design the CraftFlow icon?',
    buttonText: 'Upload Your Logo',
  },
  ctaTwo: {
    bg: 'cta-c',
    text: 'Learn How to Submit Your Logo',
    buttonText: 'View Guidelines',
  },
  faq: {
    title: 'Contest FAQs',
    subtitle: 'Common questions about the contest',
    questions: [
      {
        question: 'How do I submit my logo?',
        answer:
          'Fill out the form on the contest page and upload your logo design as a 512x512 PNG file',
      },
      {
        question: 'What are the format requirements?',
        answer:
          'Your logo must be a 512x512 PNG image created in vector format',
      },
      {
        question: 'When will the winners be announced?',
        answer:
          'Winners will be announced two weeks after the submission deadline',
      },
    ],
  },
  offer: {
    key: 'contest',
    title: 'Special Offers for Designers',
    subtitle: 'Win a premium account for one year!',
    description: 'Submit Your Logo for the CraftFlow Contest',
    buttonText: 'Submit Now',
    link: 'https://tally.so/r/w4P4rO',
  },
  footer: {
    organization: 'Public Organization "DVOSTOK"',
    registrationNumber: 'Registration Number: 44740443',
    links: [
      { href: '/privacy-policy', text: 'Privacy Policy' },
      { href: '/terms-of-use', text: 'Terms of Use' },
      { href: '/cookie-policy', text: 'Cookie Policy' },
    ],
    copyright: `Copyright © ${new Date().getFullYear()} CraftFlow. All rights reserved.`,
  },
};

export { contest };
